import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { WeblinkItem } from '../shared/models/weblink-data';

@Component({
  selector: 'app-weblinks-update-dialog',
  templateUrl: './weblinks-update-dialog.component.html',
  styleUrls: ['./weblinks-update-dialog.component.scss']
})
export class WeblinksUpdateDialogComponent {

  // booleans to control the state of the respective input fields
  public isStartDateEditable: boolean = false;
  public isStartTimeEditable: boolean = false;
  public isEndDateEditable: boolean = false;
  public isEndTimeEditable: boolean = false;

  constructor(public dialogRef: MatDialogRef<WeblinksUpdateDialogComponent>, @Inject(MAT_DIALOG_DATA) public weblink: WeblinkItem) {
  }

  /**
   * This method closes the update dialog box on cancel click
   */
  public onCancelClick(): void {
    this.dialogRef.close();
  }

  /**
   * This method closes the update dialog box on cancel click and returns a response with the updated weblink
   */
  public onUpdateClick(): void {
    this.dialogRef.close(this.weblink);
  }

  /**
   * This method reflects the updated datetime on the webpage and updates the fields of the weblink
   * 
   * @param event - input event
   */
  public handleNewInput(event: any) {
    switch(event.target.id) {
      case 'startDateInput':
        this.weblink.startDate = event.target.value
        break
      case 'startTimeInput':
        this.weblink.startTime = event.target.value
        break
      case 'endDateInput':
        this.weblink.endDate = event.target.value
        break
      case 'endTimeInput':
        this.weblink.endTime = event.target.value
        break
    }
  }

  /**
   * This method controls the "editable" state of the datetime input fields. 
   * 
   * @param eventFrom - input event
   */
  public handleEditButton(eventFrom: any) {
    switch(eventFrom) {
      case 'startDate':
        this.isStartDateEditable = !this.isStartDateEditable
        break
      case 'startTime':
        this.isStartTimeEditable = !this.isStartTimeEditable
        break
      case 'endDate':
        this.isEndDateEditable = !this.isEndDateEditable
        break
      case 'endTime':
        this.isEndTimeEditable = !this.isEndTimeEditable
        break
    }
  }
}
