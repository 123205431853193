<section *ngIf="audienceToDisplay.size > 1" class="flex flex-col items-center m-5">
    <h1 class="font-bold mb-1">WEBLINK HAS MULTIPLE AUDIENCES</h1>
    <div class="text-center mt-3">
        <p class="text-lg mb-2">Please specify which audience(s) should this weblink be deleted for:</p>
        <div class="flex flex-col items-center justify-center">
            <div *ngIf="audienceToDisplay.size < 5" >
                <div *ngFor="let audience of audienceToDisplay" class="flex">
                    <input type="checkbox" id={{audience}} value={{audience}} name="audience-{{audience}}" (change)="processCheckbox($event)"/>
                    <label for={{audience}} class="text-md ml-2">{{audience}}</label>   
                </div>   
            </div>

            <div *ngIf="audienceToDisplay.size >= 5" class="scrollable-div w-32">
                <div *ngFor="let audience of audienceToDisplay" class="flex justify-center">
                    <input type="checkbox" id={{audience}} value={{audience}} name="audience-{{audience}}" (change)="processCheckbox($event)"/>
                    <label for={{audience}} class="text-md ml-2">{{audience}}</label>   
                </div>   
            </div>
            
        </div>
    </div>
    <div class="flex my-3">
        <button (click)="onCancelClick()" class="clearButton py-1 px-4 mr-1">Cancel</button>
        <button (click)="onDeleteClick()" class="submitButton text-white py-1 px-4 ml-1">Delete</button>
    </div>
</section>

<section *ngIf="audienceToDisplay.size === 1" class="flex flex-col items-center m-5">
    <h1 class="font-bold mb-1">Are you sure?</h1>
    <div class="text-center mt-3">
        <p class="text-lg mb-2">This will remove and delete the <span class="font-bold">{{weblink.iconText}}</span> weblink from the database for <span class="font-bold" *ngFor="let audience of audienceToDisplay">{{audience}}</span> audience(s).</p>
    </div>
    <div class="flex my-3">
        <button (click)="onCancelClick()" class="clearButton py-1 px-4 mr-1">Cancel</button>
        <button (click)="onDeleteClick(audienceToDisplay)" class="submitButton text-white py-1 px-4 ml-1">Delete</button>
    </div>
</section>