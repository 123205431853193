import { Component, inject, OnInit } from '@angular/core';
import { WeblinksService } from '../weblinks.service';
import { WeblinkStorage, WeblinkItem } from '../shared/models/weblink-data';
import { WeblinksDeleteDialogComponent } from '../weblinks-delete-dialog/weblinks-delete-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { WeblinksUpdateDialogComponent } from '../weblinks-update-dialog/weblinks-update-dialog.component';
import { ListAllPreferenceGroupingsResp, PreferenceGrouping } from '@xpo-ltl/sdk-userpreference';

/**
 * Enum for defining the status of a weblink
 */
const LINKSTATUS = Object.freeze({
  PENDING: 0,
  EXPIRED: 1,
  LIVE: 2,
  UNKNOWN: -1,
});

@Component({
  selector: 'app-weblinks-status',
  templateUrl: './weblinks-status.component.html',
  styleUrls: ['./weblinks-status.component.scss'],
})
export class WeblinksStatusComponent implements OnInit {

  weblinkService = inject(WeblinksService)
  weblinksToDisplay: WeblinkItem[] = [] // weblinks which will be displayed onto the status page
  weblinksTracker: Map<string, string> = new Map<string, string>(); // map used to handle multiple renderings of same weblink

  constructor(public dialog: MatDialog){}
  
  /**
   * This method is executed each time the componenet is initalized, and sets up the status page by fetching 
   * and rendering all active weblinks in the database.
   */
  public async ngOnInit(): Promise<void> {

    try {
      const response: ListAllPreferenceGroupingsResp = await this.weblinkService.getWeblinks()
      const preferenceGroupings: PreferenceGrouping[] = response.preferenceGroupings

      // clear weblinks and weblinksTracker map to avoid re-renders
      this.weblinksToDisplay = []
      this.weblinksTracker.clear();

      // iterate through all the groupings and their weblinks to find all active weblinks to render
      for (const grouping of preferenceGroupings) {

        const weblinks: any[] = JSON.parse(grouping.groupingPreference)

        weblinks.forEach(async (weblink: WeblinkStorage) => {

          let timeRemaining: string;
          const linkStatus: number = this.getLinkStatus(weblink)
          
          // get the time remaining depending on the linkStatus
          if(linkStatus === LINKSTATUS.PENDING) {
            timeRemaining = this.getTimeRemaining(weblink, linkStatus)
          } else {
            timeRemaining = this.getTimeRemaining(weblink, linkStatus)
          }

          // split the effective and expiration dates into four fields for easier rendering on the html side
          const effectiveDateSplit = weblink.effectiveDate.toString().split('T');
          const expirationDateSplit = weblink.expirationDate.toString().split('T');


          // fetch all audiences which have the current weblink
          const sicCodeAudiences: string[] = await this.fetchAudiences(weblink);

          // define the WeblinkItem which will be displayed onto the html page
          const weblinkData: WeblinkItem = {
            iconText: weblink.iconText,
            url: weblink.url,
            icon: weblink.icon,
            startDate: effectiveDateSplit[0],
            startTime: effectiveDateSplit[1].substring(0,5), // trunct to just HH:MM
            endDate: expirationDateSplit[0],
            endTime: expirationDateSplit[1].substring(0,5),
            timeRemaining: timeRemaining,
            status: linkStatus,
            audience: grouping.groupingName === "All" ? ['All'] : sicCodeAudiences
          }
          
          /**
           * The key is set to prevent multiple re-renders on the status page. If a weblink has multiple audiences (i.e sic codes),
           * then we only want that weblink to be added to the list of weblinks to display only once.
           */
          const key = weblinkData.iconText+weblinkData.url+weblinkData.audience.toString();
          if(!this.weblinksTracker.has(key)) {
            this.weblinksToDisplay.push(weblinkData)
            this.weblinksTracker.set(key, weblinkData.iconText)
          }          

        });
        
      }

    } catch (error) {
      console.log(`Error initializing status component:`, error)
    }
  }


  /**
   * This method gets all audiences (i.e groupingNames) which have the given weblink in their preferenceGroupings.
   * 
   * @param weblink - weblink for which the audiences should be fetched for
   * @returns - string[] upon promise fulfilment that has the audiences of the given weblink
   */
  private fetchAudiences(weblink: WeblinkStorage): Promise<string[]> {
    
    return new Promise(async (resolve) => {
      const audiences: string[] = []

      const response = await this.weblinkService.getWeblinks();
      const preferenceGroupings: PreferenceGrouping[] = response.preferenceGroupings;
      
      for(const grouping of preferenceGroupings) {
        const weblinks: WeblinkStorage[] = JSON.parse(grouping.groupingPreference);
        for(const link of weblinks) {
          if((link.iconText === weblink.iconText && link.url == weblink.url) && grouping.groupingName !== "All") {
            audiences.push(grouping.groupingName);
          }
        }
      }

      resolve(audiences);
    })
  }


  /**
   * This method gets the current status of a given weblink
   * 
   * @param weblink - weblink to get the status of
   * @returns - enum integer representing the state of the weblink
   */
  private getLinkStatus(weblink: WeblinkStorage): number {
    const endDate = new Date(weblink.expirationDate);
    const startDate = new Date(weblink.effectiveDate);

    if(startDate.getTime() > Date.now() && Date.now() < endDate.getTime() && endDate.getTime() > startDate.getTime()) {
      return LINKSTATUS.PENDING
    } else if (endDate.getTime() > Date.now()) {
      return LINKSTATUS.LIVE
    } else if (endDate.getTime() < Date.now()) {
      return LINKSTATUS.EXPIRED
    }
    return LINKSTATUS.UNKNOWN;
  }


  /**
   * This method calcualtes the remaining time for a weblink start from current time to the weblink's end time.
   * 
   * @param weblink - weblink to calcualte the end time for
   * @param linkStatus - status of the current link to help with formating and adjusting end time
   * @returns - a string in the format days:hours:mins remaining until end time is reached
   */
  private getTimeRemaining(weblink: WeblinkStorage, linkStatus?: number): string {
    const startDate = Date.now();
    const endDateTime = linkStatus === 0 ? new Date(weblink.effectiveDate) : new Date(weblink.expirationDate) 

    const diffMs = endDateTime.getTime() - startDate;

    const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24))
    const diffHours = Math.floor((diffMs % (1000 * 60 * 60 * 24)) / ((1000 * 60 * 60)))
    const diffMins = Math.floor((diffMs %  (1000 * 60 * 60)) / (1000 * 60));

    let remainingTime: string = ""
    if (diffDays < 0 || diffHours < 0 || diffMins < 0){
      remainingTime = "- - -" 
    } else {

      if(linkStatus === LINKSTATUS.PENDING) {
        remainingTime = `Live in: ${diffDays}d ${diffHours}h ${diffMins}m `
      } else {
        remainingTime = `${diffDays}d ${diffHours}h ${diffMins}m `
      }
    }

    return remainingTime;
  }

  // function to check if a given [weblink] is active or expired

  /**
   * This method checks if the given weblink is active (i.e live)
   * 
   * @param weblink - weblink to validate
   * @returns - boolean value indicating if weblink is valid or not
   */
  public isWeblinkValid(weblink: WeblinkStorage): boolean {
    const endDate = new Date(weblink.expirationDate);
    return endDate.getTime() > Date.now()
  }

  // function to update a given [weblink] in the user preferences database

  /**
   * This method updates the fields of the given weblink.
   * 
   * @param weblink - weblink to update
   */
  public updateWeblink(weblink: WeblinkItem): void {
    this.dialog.open(
      WeblinksUpdateDialogComponent, 
      {
        width: '400px',
        data: weblink
      }
    )
    .afterClosed()
    .subscribe({
      next: async (response: any) => {
        if(response) {
          await this.weblinkService.updateWeblink(weblink);
          this.ngOnInit();
        }
      },
      error: (err) => {
        console.log(err)
      },
    })
  }

  
  /**
   * This method removes a given weblink from the User Preference database
   * 
   * @param weblink - weblink to remove
   */
  public removeWeblink(weblink: WeblinkItem): void {
    this.dialog.open(
      WeblinksDeleteDialogComponent, 
      {
        width: '400px',
        data: weblink
      }
    )
    .afterClosed()
    .subscribe({
      next: async (audiencesToRemove: any) => {
        if(audiencesToRemove) {
          await this.weblinkService.removeWeblink(weblink, audiencesToRemove)
          this.ngOnInit();
        }
      },
      error: (err) => {
        console.log(err)
      },
    })
  } 
}
