import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { WeblinkItem } from '../shared/models/weblink-data';

@Component({
  selector: 'app-weblinks-delete-dialog',
  templateUrl: './weblinks-delete-dialog.component.html',
  styleUrls: ['./weblinks-delete-dialog.component.scss']
})
export class WeblinksDeleteDialogComponent {

  // sets to store audiences to delete and display
  private audienceToDelete = new Set<string>()
  public audienceToDisplay = new Set<string>()

  /**
   * Constructor to filter a weblink into the proper format to display on the delate dialog
   * 
   * @param dialogRef - reference to the material dialog
   * @param weblink - weblink for delete event
   */
  constructor(public dialogRef: MatDialogRef<WeblinksDeleteDialogComponent>, @Inject(MAT_DIALOG_DATA) public weblink: WeblinkItem) {
    if(weblink.audience[0] === "All") {
      this.audienceToDisplay.add(weblink.audience[0])
    } else {
      if(weblink.audience.length > 0) {
        weblink.audience.forEach((sicCode: string) => {
          this.audienceToDisplay.add(sicCode)
        });
      }
    }
  }
  
  /**
   * This method adds each of the checked audiences of the weblink to an array to be deleted.
   * 
   * @param event - checkbox selection event
   */
  public processCheckbox(event: any): void {
    if(event.target.checked) {
      this.audienceToDelete.add(event.target.value)
    } else {
      this.audienceToDelete.delete(event.target.value)
    }
  }

  /**
   * Method to close the delete dialog on cancel click
   */
  public onCancelClick(): void {
    this.dialogRef.close();
  }

  /**
   * Method to close the delete dialog and return a list of audiences to delete this weblink from
   * 
   * @param singleAudience - optional parameter for when only a single audience needs to be deleted
   */
  public onDeleteClick(singleAudience?: Set<string>): void {
    if (singleAudience) {
      singleAudience.forEach((audienceName: string) => {
        this.audienceToDelete.add(audienceName)
      });
    }
    this.dialogRef.close(this.audienceToDelete)
  }
}
