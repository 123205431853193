import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { XpoShellModule } from '@xpo-ltl/ngx-ltl-core/shell';
import { XpoFooterModule } from '@xpo-ltl/ngx-ltl-core/footer';
import { XpoLtlAuthUiModule } from '@xpo-ltl/ngx-auth-ui';
import { XPO_AUTH_CONFIG, XpoAuthConfig } from '@xpo/ngx-auth';
import { XpoLtlAuthConfigLoaderService, XpoLtlAuthModule } from '@xpo-ltl/ngx-auth';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from '../environments/environment';
import { CONFIG_MANAGER_SERVICE_CONFIGURATION, ConfigManagerModule } from '@xpo-ltl/config-manager';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { DataApiModule } from '@xpo-ltl/data-api';
import { XpoCardModule } from '@xpo-ltl/ngx-ltl-core/card';
import { UserPreferenceApiService } from '@xpo-ltl/sdk-userpreference';
import {MatSnackBarModule} from '@angular/material/snack-bar'; 
import { MatButtonModule } from '@angular/material/button';
import { WeblinksStatusComponent } from './weblinks-status/weblinks-status.component';
import { WeblinksAddComponent } from './weblinks-add/weblinks-add.component';   
import {MatDialogModule} from '@angular/material/dialog';
import {MatIcon, MatIconModule} from '@angular/material/icon';
import { WeblinksDeleteDialogComponent } from './weblinks-delete-dialog/weblinks-delete-dialog.component';
import { FormsModule } from '@angular/forms';
import { WeblinksUpdateDialogComponent } from './weblinks-update-dialog/weblinks-update-dialog.component';


export function authConfigFactory(configService: XpoLtlAuthConfigLoaderService): XpoAuthConfig {
  configService.initSettings('./assets/config.json');

  return new XpoAuthConfig({
    appCode: configService.appCode,
    scopes: configService.scopes,
    isProd: configService.isProd,
    applicationRootUri: configService.appRootUri,
  });
}

@NgModule({
  declarations: [AppComponent, WeblinksStatusComponent, WeblinksAddComponent, WeblinksDeleteDialogComponent, WeblinksUpdateDialogComponent],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    XpoLtlAuthModule.forRoot({
      provide: XPO_AUTH_CONFIG,
      useFactory: authConfigFactory,
      deps: [XpoLtlAuthConfigLoaderService],
    }),
    ConfigManagerModule,
    DataApiModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    XpoShellModule,
    XpoFooterModule,
    FormsModule,
    XpoCardModule,
    XpoLtlAuthUiModule,
    AppRoutingModule,
    MatSnackBarModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule
  ],
  providers: [
    {
      provide: CONFIG_MANAGER_SERVICE_CONFIGURATION,
      useValue: { configFileUrl: './assets/config.json' },
    },
    UserPreferenceApiService,
    MatIcon
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
