<section class="flex flex-col mt-5 mx-5 h-screen">
  <div class="flex gap-x-4">
    <h1 class="flex justify-start items-center gap-x-1 text-3xl font-bold mb-2">
      Weblinks Status 
      <button class="flex items-center bg-slate-50 font-bold text-black text-sm hover:text-red-500" (click)=" this.ngOnInit()">
        <mat-icon aria-hidden="false" aria-label="Refresh Icon" fontIcon="refresh"></mat-icon>
      </button>
    </h1>
  </div>
    
  <table class="border border-white shadow-sm shadow-black">
      <thead>
        <tr class="bg-red-600 text-white text-md font-bold">
          <th>Weblink</th>
          <th>URL</th>
          <th>Audience</th>
          <th>Effective Date (YYYY-MM-DD)</th>
          <th>Effective Time (24-hour)</th>
          <th>Expiration Date (YYYY-MM-DD)</th>
          <th>Expiration Time (24-hour)</th>
          <th>Time Remaining</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let link of weblinksToDisplay" class="text-center">

          <td class="font-bold">{{ link.iconText }}</td>

          <td><a href={{link.url}} target="_blank">{{ link.url }}</a></td>

          <td>
            <p *ngIf="link.audience[0] === 'All'">{{link.audience[0]}}</p>
            <p *ngIf="link.audience[0] !== 'All' && link.audience.length === 1">{{link.audience}}</p>

            <div *ngIf="link.audience[0] !== 'All' && link.audience.length > 1">
              <details *ngIf="link.audience.length > 0 && link.audience[0] !== 'All'" class="cursor-pointer">
                <summary>SIC codes</summary>
                <div class="scrollable-div" *ngIf="link.audience.length >= 5">
                  <ul>
                    <li class="font-bold" *ngFor="let SIC of link.audience">{{SIC}}</li>
                  </ul>
                </div>
                <div *ngIf="link.audience.length < 5">
                  <ul>
                    <li class="font-bold" *ngFor="let SIC of link.audience">{{SIC}}</li>
                  </ul>
                </div>
              </details>
            </div>
          </td>
          <td>{{ link.startDate }}</td>
          <td>{{ link.startTime }}</td>
          <td>{{ link.endDate }}</td>
          <td>{{ link.endTime }}</td>
          <td>{{ link.timeRemaining }}</td>
          <td *ngIf="link.status === 0" class="bg-yellow-400 font-bold">Pending</td>
          <td *ngIf="link.status === 1" class="bg-red-400 font-bold">Expired</td>
          <td *ngIf="link.status === 2" class="bg-green-400 font-bold">Live</td>
          <td>
            <div class="flex justify-center gap-x-2">
              <button class="flex items-center rounded-lg p-1 bg-slate-50 border-black border font-bold text-black text-lg hover:bg-slate-200" (click)="this.updateWeblink(link)">
                <mat-icon aria-hidden="false" aria-label="Edit Icon" fontIcon="edit"></mat-icon>
              </button>
              <button class="flex items-center rounded-lg p-1 bg-slate-50 border-black border font-bold text-black text-lg hover:bg-red-500 hover:text-white" (click)="this.removeWeblink(link)">
                <mat-icon aria-hidden="false" aria-label="Trash Icon" fontIcon="delete_forever"></mat-icon>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <h2 *ngIf="weblinksToDisplay.length === 0" class="text-center text-bold text-xl">No weblinks found.</h2>
</section>