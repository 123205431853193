import { Component, inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { WeblinksService } from '../weblinks.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WeblinkItem } from '../shared/models/weblink-data';

@Component({
  selector: 'app-weblinks-add',
  templateUrl: './weblinks-add.component.html',
  styleUrls: ['./weblinks-add.component.scss']
})
export class WeblinksAddComponent {
  
  private file: any; // icon image file

  addWeblistForm = new FormGroup({
    iconText: new FormControl(''),
    iconUrl: new FormControl(''),
    iconImage: new FormControl(''),
    iconType: new FormControl('svg'),
    alertText: new FormControl(''),
    startDate: new FormControl(''),
    startTime: new FormControl(''),
    endDate: new FormControl(''),
    endTime: new FormControl(''),
    isPositionFirstInd: new FormControl(false),
    audienceSicCodes: new FormControl([]),
    isAudienceAll: new FormControl(true),
    isAudienceSicCodes: new FormControl(false)
  })
  
  weblinkService = inject(WeblinksService)

  constructor(private _snackbar: MatSnackBar){}

  /**
   * This method opens a simple snackbar menu at the bottom of the screen with a given message
   * 
   * @param message - message to display in the snackbar
   */
  private openSnackBar(message: string): void { 
    this._snackbar.open(message, '', { 
      duration: 2000, 
      horizontalPosition: 'center',
      verticalPosition: 'bottom'
    }); 
  } 


  /**
   * This method "selects" the audience checkbox on the angular form
   */
  private checkAllAudience(): void {
    if(!this.addWeblistForm.value.isAudienceSicCodes){
      this.addWeblistForm.patchValue({
        isAudienceAll: true
      })
    }
  }


  /**
   * This method updates the UI on the angular form for the given audience value.
   * 
   * @param value - audience value for which to update
   */
  public updateAudience(value: string): void  {
    switch(value){
      case 'all':
        this.addWeblistForm.patchValue({
          isAudienceAll: true,
          isAudienceSicCodes: false,
          audienceSicCodes: []
        })
        break;

      case 'sicCodes':
        this.addWeblistForm.patchValue({
          isAudienceAll: false,
          isAudienceSicCodes: !this.addWeblistForm.value.isAudienceSicCodes,
          audienceSicCodes: []
        })
        this.checkAllAudience();
        break;
    }
  }


  /**
   * This method resets (i.e clears) the angular form
   */
  public clearForm(): void {
    this.addWeblistForm.reset({isAudienceAll: true});
  }


  /**
   * This method saves input characters in the sic codes field as they are entered.
   * 
   * @param event - input event from sic codes input field
   */
  public selectSIC(event: any): void {
    this.addWeblistForm.patchValue({
      isAudienceSicCodes: true,
      isAudienceAll: false,
      audienceSicCodes: event.target.value.toUpperCase().split(',')
    })
  }


  /**
   * This method saves the selected image file from the html element into a class variable.
   * 
   * @param event - file selection event
   */
  public setFile(event: any): void {
    this.file = event.target.files[0]
  }

  
  /**
   * This method formats the icon image field and upserts the weblink data in the angular form.
   */
  public postWeblink(): void {
    try {
      let fileReader = new FileReader();
      fileReader.onload = (e) => {
        this.addWeblistForm.patchValue({
          iconImage: "data:image/svg+xml;utf8," + fileReader.result
        })

        // define the new weblink data
        const newWeblinkData: WeblinkItem = {
          iconText: this.addWeblistForm.value.iconText ?? '',
          url: this.addWeblistForm.value.iconUrl ?? '',
          icon: this.addWeblistForm.value.iconImage ?? '',
          iconType: this.addWeblistForm.value.iconType ?? '',
          alertText: this.addWeblistForm.value.alertText ?? '',
          startDate: this.addWeblistForm.value.startDate ?? '',
          startTime: this.addWeblistForm.value.startTime ?? '',
          endDate: this.addWeblistForm.value.endDate ?? '',
          endTime: this.addWeblistForm.value.endTime ?? '',
          iconPositionFirstInd: this.addWeblistForm.value.isPositionFirstInd ?? false,
          audience: this.addWeblistForm.value.isAudienceAll as boolean ? ['All'] : this.addWeblistForm.value.audienceSicCodes ?? []
        } 

        this.weblinkService.upsertWeblink(newWeblinkData)
      }

      fileReader.readAsText(this.file);
      
    } catch (error: any) {
      console.log(error)
      this.openSnackBar(`ERROR: ${error}`)
    }
  }
}
