<section class="h-screen mt-5 mx-5 flex flex-col ">
    <h1 class="text-3xl font-bold mb-0">Weblink Information</h1>
    <p class="mb-2 text-red-500">* Please complete all required fields before submitting</p>
    
    <form [formGroup]="addWeblistForm" class="items-start justify-center p-5 w-1/2 h-fit border border-white shadow-sm shadow-black" (submit)="postWeblink()" ngNativeValidate>
            <div class="row">
                <div class="input-container w-1/3">
                    <label for="iconText">*Icon text</label>
                    <input id="iconText" type="text" placeholder="Text to display under icon..." formControlName="iconText" required/>
                </div>
  
                <div class="input-container w-2/5">
                    <label for="iconURL">*Icon URL</label>
                    <input id="iconURL" type="text" placeholder="URL associated with icon..." formControlName="iconUrl" required/>
                </div>
  
                <div class="input-container w-2/5 mr-2">
                    <label for="iconAlertText">Alert text</label>
                    <input id="iconAlertText" class="iconURL" type="text" placeholder="Alert text associated with pop up dialogs..." formControlName="alertText"/>
                </div>              
            </div>
  
            <div class="row">
                <div class="input-container justify-center w-1/2">
                    <fieldset class="mt-2">
                        <legend>Audience</legend>
                      
                        <div class="flex">
                            <input type="checkbox" id="all" name="audience-all" value="all" formControlName="isAudienceAll" (click)="updateAudience('all')"/>
                            <label for="all" class="text-md ml-2">All</label>    
                        </div>

                        <div class="flex">
                            <input type="checkbox" id="sicCodes" name="audience-sicCodes" value="sicCodes" formControlName="isAudienceSicCodes" (click)="updateAudience('sicCodes')"/>

                            <div class="input-container w-full">
                                <label for="sicCodes">SIC codes</label>
                                <input autocomplete="off" onkeypress="return event.charCode != 32" type="text" id="sicCodes" placeholder="Ex. UPO,ULV,URD" formControlName="audienceSicCodes" (input)="selectSIC($event)"/>
                            </div>
                            
                        </div>
                    </fieldset>

                    <div class="flex justify-center w-1/2">
                        <div class="border border-white shadow-sm shadow-black rounded-lg text-sm flex flex-col text-center justify-center items-center w-fit mt-5 p-5">
                            <input id="isFirstIdx" type="checkbox"formControlName="isPositionFirstInd" />
                            <label class="mt-2" for="isFirstIdx">Display icon as first in the list? (Check for YES) </label>
                        </div>
                    </div>
                    

                </div>

                <div class="input-container justify-center w-1/2">

                    <div class="flex">
                        <div class="input-container w-full">
                            <label for="iconImg">*Icon image (.svg)</label>
                            <input
                                id="iconImg" class="file-uploader"
                                type="file"
                                (change)="setFile($event)"
                                accept="image/svg"
                                class="bg-white text-sm"
                                required
                            />
                        </div>
                    </div>

                    <div class="flex">
                        <div class="input-container w-1/2"> 
                            <label for="startDate">*Start date</label>
                            <input type="date" id="startDate" name="startDate" formControlName="startDate" required/>
                        </div>
                        
                        <div class="input-container w-1/2">
                            <label for="startTime">*Start time</label>
                            <input type="time" id="startTime" name="startTime" formControlName="startTime" required>
                        </div>
        
                    </div>
                    
                    <div class="flex">
                        <div class="input-container w-1/2">
                            <label for="endDate">*End date</label>
                            <input type="date" id="endDate" name="endTime" formControlName="endDate" required/>
                        </div>
                        
                        <div class="input-container w-1/2">
                            <label for="endTime">*End time</label>
                            <input type="time" id="endTime" name="endTime" formControlName="endTime" required>
                        </div>
                    </div>  
                </div>
            </div>
  
            <div class="flex justify-end items-center mt-14 gap-x-5 mr-5">
                <button type="button" class="clearButton" (click)="clearForm()">Clear</button>
                <button type="submit" class="submitButton">Submit</button>
            </div>
            
    </form>
  </section>