<section class="flex flex-col p-5">
<div class="header text-center mb-2">
    <p class="text-2xl">Editing info for <span class="font-bold">{{weblink.iconText}}</span></p>
</div>
<div class="body text-lg">
    <ul>
        <li><span class="font-bold">Weblink name:</span> {{weblink.iconText}}</li>
        <li><span class="font-bold">URL:</span> {{weblink.url}}</li>
        <li class="flex"><span class="font-bold mr-1">Audience:</span> 
            <div class="scrollable-div" *ngIf="weblink.audience[0] === 'All'">{{weblink.audience[0]}}</div>
            <div class="scrollable-div" *ngIf="weblink.audience[0] !== 'All'">
                <span *ngIf="weblink.audience[0] !== 'All'">{{weblink.audience}}</span>
            </div>
        </li>
        <li class="flex items-center">
            <p *ngIf="!isStartDateEditable"><span class="font-bold">Start date:</span> {{weblink.startDate}} 
            <p *ngIf="isStartDateEditable"><span class="font-bold">Start date:</span> <input type="date" id="startDateInput" value={{weblink.startDate}} (change)="handleNewInput($event)" class="ml-1 border border-black w-fit"></p>
            <button class="font-bold text-black hover:text-red-500" (click)="handleEditButton('startDate')">
                <mat-icon *ngIf="!isStartDateEditable" class="text-sm" aria-hidden="false" aria-label="Edit Icon" fontIcon="edit"></mat-icon>
                <mat-icon *ngIf="isStartDateEditable" class="text-sm" aria-hidden="false" aria-label="Edit Icon" fontIcon="done"></mat-icon>
            </button>
        </li>
        <li class="flex items-center">
            <p *ngIf="!isStartTimeEditable"><span class="font-bold">Start time:</span> {{weblink.startTime}} </p>
            <p *ngIf="isStartTimeEditable"><span class="font-bold">Start time:</span> <input type="time" id="startTimeInput" value={{weblink.startTime}} class="ml-1 border border-black w-32" (change)="handleNewInput($event)"></p>
            <button class="font-bold text-black hover:text-red-500" (click)="handleEditButton('startTime')">
                <mat-icon *ngIf="!isStartTimeEditable" class="text-sm" aria-hidden="false" aria-label="Edit Icon" fontIcon="edit"></mat-icon>
                <mat-icon *ngIf="isStartTimeEditable" class="text-sm" aria-hidden="false" aria-label="Edit Icon" fontIcon="done"></mat-icon>
            </button>
        </li>
        <li class="flex items-center">
            <p *ngIf="!isEndDateEditable"><span class="font-bold">End date:</span> {{weblink.endDate}} </p>
            <p *ngIf="isEndDateEditable"><span class="font-bold">End date:</span> <input type="date" id="endDateInput" value={{weblink.endDate}} class="ml-1 border border-black w-fit" (change)="handleNewInput($event)"></p>
            <button class="font-bold text-black hover:text-red-500" (click)="handleEditButton('endDate')">
                <mat-icon *ngIf="!isEndDateEditable" class="text-sm" aria-hidden="false" aria-label="Edit Icon" fontIcon="edit"></mat-icon>
                <mat-icon *ngIf="isEndDateEditable" class="text-sm" aria-hidden="false" aria-label="Edit Icon" fontIcon="done"></mat-icon>
            </button>
        </li>
        <li class="flex items-center">
            <p *ngIf="!isEndTimeEditable"><span class="font-bold">End time:</span> {{weblink.endTime}} </p>
            <p *ngIf="isEndTimeEditable"><span class="font-bold">End time:</span> <input type="time" id="endTimeInput" value={{weblink.endTime}} class="ml-1 border border-black w-32" (change)="handleNewInput($event)"></p>
            <button class="font-bold text-black hover:text-red-500" (click)="handleEditButton('endTime')">
                <mat-icon *ngIf="!isEndTimeEditable" class="text-sm" aria-hidden="false" aria-label="Edit Icon" fontIcon="edit"></mat-icon>
                <mat-icon *ngIf="isEndTimeEditable" class="text-sm" aria-hidden="false" aria-label="Edit Icon" fontIcon="done"></mat-icon>
            </button>
        </li>
    </ul>
</div>
<div class="buttonMenu flex justify-end items-center gap-x-3 mt-5">
    <button type="button" class="cancelButton" (click)="onCancelClick()">Cancel</button>
    <button type="submit" class="submitButton" (click)="onUpdateClick()">Update</button>
</div>
</section>