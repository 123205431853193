import { Injectable } from '@angular/core';
import { UserPreferenceApiService, ListAllPreferenceGroupingsResp, PreferenceGrouping, ListAllPreferenceGroupingsQuery } from '@xpo-ltl/sdk-userpreference';
import { ActionCd, ListInfo  } from '@xpo-ltl/sdk-common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WeblinkStorage, WeblinkItem} from './shared/models/weblink-data';

@Injectable({
  providedIn: 'root',
})
export class WeblinksService {

  private DEFAULT_NUMBER_OF_ROWS = 100;
  private readonly UI_COMPONENT = "mobileWebLinks";
  private readonly ALL_AUDIENCE = "All"

  constructor(private userPreferenceService: UserPreferenceApiService, private snackbar: MatSnackBar) {}

  /**
   * This method opens a temporary snackbar at the bottom of the screen with the given message
   * 
   * @param message - message to be displayed in the snackbar
   */
  private openSnackBar(message: string): void { 
    this.snackbar.open(message, '', { 
      duration: 3000, 
      horizontalPosition: 'center',
      verticalPosition: 'bottom'
    }); 
  } 


  /**
   * This method adds delay to program execution.
   * 
   * @param ms - number of miliseconds to delay the program execution
   * @returns - when the promise is resolved after [ms] seconds
   */
  private delay(ms: number) {
    return new Promise((resolve) => {setTimeout(resolve, ms)})
  }

  
  /**
   * This method upserts a new preference grouping into the mobileWeblinks uiComponent.
   * 
   * @param audience - the audience to which to upsert the new weblinkStarting
   * @param webLinksString - stringified version of weblinks of type WeblinkStorage
   * @param newData - boolean value to indiciate if data is new or existing
   * @param groupingId - optinal groupingId provided for groupings which already exist in order to perform an UDPATE
   * @returns 
   */
  private upsertPreferenceGrouping(audience: string, webLinksString: string, newData: boolean, groupingId?: number): Promise<void> {
    
    const preferenceGrouping = new PreferenceGrouping();

    if (!newData) {
      preferenceGrouping.groupingId = groupingId ?? 0;
    }
    preferenceGrouping.groupingName = audience;
    preferenceGrouping.groupingPreference = webLinksString;
    preferenceGrouping.listActionCd = newData ? ActionCd.ADD : ActionCd.UPDATE;

    return new Promise((resolve, reject) => { 
      this.userPreferenceService.upsertPreferenceGrouping({
        uiComponentName: this.UI_COMPONENT,
        preferenceGrouping: preferenceGrouping
      })?.subscribe({
        next: () => {
            resolve();
        }, 
        error: (err) => {
          console.log(err)
            reject(err);
        },
      });
    });
  }


  /**
   * This method upserts a weblink into the database depending on the audience
   * 
   * @param weblink - new WeblinkItem object to be upserted
   */
  public async upsertWeblink(weblink: WeblinkItem): Promise<void> {

    const newWeblinkData: WeblinkStorage = {
      url: weblink.url,
      icon: weblink.icon ?? '',
      iconType: weblink.iconType ?? '',
      iconText: weblink.iconText,
      iconPositionFirstInd: weblink.iconPositionFirstInd ?? false,
      alertText: weblink.alertText ?? '',
      effectiveDate: new Date(Date.parse(weblink.startDate+"T"+weblink.startTime)),
      expirationDate: new Date(Date.parse(weblink.endDate+"T"+weblink.endTime))
    };

    // fetch all active weblinks
    const response: ListAllPreferenceGroupingsResp = await this.getWeblinks()
    const preferenceGroupings: PreferenceGrouping[] = response?.preferenceGroupings

    // ADD or UPDATE weblinks for ALL audiences
    if(weblink.audience.includes(this.ALL_AUDIENCE)) {

      try {
        let existingGroupingId: number = -1;

        // filter to find grouping for all audiences
        const allAudienceGrouping = preferenceGroupings?.filter((grouping: PreferenceGrouping) => {
          const groupingTest = grouping.groupingName?.toLowerCase() === this.ALL_AUDIENCE.toLowerCase()
          groupingTest ? existingGroupingId = grouping.groupingId : -1
          return groupingTest
        })[0];

        // if a grouping is found, append newWebLinkData to list of existing weblinks and make request
        if(allAudienceGrouping) {
          const allAudienceWeblinks: WeblinkStorage[] = JSON.parse(allAudienceGrouping?.groupingPreference ?? '');
          allAudienceWeblinks.push(newWeblinkData);

          const request: string = JSON.stringify(allAudienceWeblinks);

          await this.upsertPreferenceGrouping("All", request, false, existingGroupingId)
          this.openSnackBar(`${weblink.iconText} weblink upserted for ALL audiences.`)

        } else {
          // if not grouping is found, make the request with newWeblinkData alone
          const request: string = JSON.stringify([newWeblinkData]);
          await this.upsertPreferenceGrouping(this.ALL_AUDIENCE, request, true)
          this.openSnackBar(`${weblink.iconText} weblink created for ALL audiences.`)
        }

      } catch (error) {
        console.error(`Error upserting for ALL audience:`, error);
      }
      
    } else {

      // ADD or UPDATE weblinks for all sic code in audience array
      for (const code of weblink.audience) {
        try {
          let existingGroupingId: number = -1;
  
          // attempt to get grouping for current Sic
          const currentSicGrouping = preferenceGroupings?.filter((grouping: PreferenceGrouping) => {
            const groupingTest = grouping.groupingName?.toLowerCase() === code.toLowerCase()
            groupingTest ? existingGroupingId = grouping.groupingId : -1
            return groupingTest
          })[0];
  
          // if a grouping for current sic is found, append newWebLinkData to list of existing weblinks and make request
          if(currentSicGrouping) {
            const currentSicWeblinks: WeblinkStorage[] = JSON.parse(currentSicGrouping?.groupingPreference ?? '');
            currentSicWeblinks.push(newWeblinkData);
  
            const request: string = JSON.stringify(currentSicWeblinks);
  
            await this.upsertPreferenceGrouping(code, request, false, existingGroupingId)
            this.openSnackBar(`${weblink.iconText} weblink updated for ${code}.`)
          } else {
  
            // if not grouping is found, make a request with newWeblinkData alone
            const request: string = JSON.stringify([newWeblinkData]);
            await this.upsertPreferenceGrouping(code, request, true)
            this.openSnackBar(`${weblink.iconText} weblink created successfuly.`)
          }
        
          await this.delay(100);
  
        } catch (error) {
          console.error(`Error processing code ${code}:`, error);
        }
      };

    }
  }


  /**
   * This method fetches all active weblinks from the defined UI_COMPONENT name.
   * 
   * @returns - a promise of type ListAllPreferenceGroupingsResp once fulfilled
   */
  public async getWeblinks(): Promise<ListAllPreferenceGroupingsResp> {

    const listAllPreferenceGroupingsQuery = new ListAllPreferenceGroupingsQuery()
    listAllPreferenceGroupingsQuery.uiComponentName = this.UI_COMPONENT
    
    // update listInfo with totalRowCount and then make final listAllpreferenceGroupings all to display ALL rows
    const listInfo = new ListInfo();
    listInfo.numberOfRows = this.DEFAULT_NUMBER_OF_ROWS;
    listAllPreferenceGroupingsQuery.listInfo = listInfo;

    return new Promise((resolve, reject) => {
      this.userPreferenceService.listAllPreferenceGroupings(listAllPreferenceGroupingsQuery)
      .subscribe({
        next: async (initialResponse: any) => {
          const totalRowCount = initialResponse?.listInfo?.totalRowCount

          if (totalRowCount > listInfo.numberOfRows) {
            // set startAt to current numberOfRows, and make the call again
            listInfo.startAt = listInfo.numberOfRows;
            listInfo.numberOfRows = totalRowCount;

            const result: Promise<ListAllPreferenceGroupingsResp> = new Promise((resolveWithUpdate, rejectWithUpdate) => {
              this.userPreferenceService.listAllPreferenceGroupings(listAllPreferenceGroupingsQuery)?.subscribe({
                next: (updatedResponse: any) => {
                  // concat initial call results to updated call results before resolving
                  updatedResponse!.preferenceGroupings = updatedResponse?.preferenceGroupings.concat(initialResponse?.preferenceGroupings)
                  resolveWithUpdate(updatedResponse);
                },
                error: (error: any) => {
                  rejectWithUpdate(error);
                },
              });
            });

            resolve(await result);
          } else {
            resolve(initialResponse);
          }
        }, 
        error: (err) => {
          reject(err);
        },
      })
    })

  }


  /**
   * This method performs an update on a given weblink.
   * 
   * @param weblink - the weblink to be updated
   */
  public async updateWeblink(weblink: WeblinkItem): Promise<void> {

    try {

      const response: ListAllPreferenceGroupingsResp = await this.getWeblinks()
      const preferenceGroupings: PreferenceGrouping[] = response?.preferenceGroupings;
    
      let audiences: string[] = weblink.audience
      
      audiences?.forEach(async (audience: string) => {

        let existingGroupingId = -1;

        // for the grouping for current audience
        const currentAudienceGrouping = preferenceGroupings?.filter((grouping: PreferenceGrouping) => {
          const groupingTest = grouping.groupingName?.toLowerCase() === audience.toLowerCase()
          groupingTest ? existingGroupingId = grouping.groupingId : -1
          return groupingTest
        })[0];

        // if grouping is found, parse the current grouping weblinks to find the weblink that matches the weblink to be updated
        if(currentAudienceGrouping) {
          const weblinks: WeblinkStorage[] = JSON.parse(currentAudienceGrouping?.groupingPreference ?? '');
          const updatedWeblinks: WeblinkStorage[] = []

          weblinks.forEach((currentLink: WeblinkStorage) => {
            if(currentLink.iconText === weblink.iconText && currentLink.url === weblink.url) {
              currentLink.effectiveDate = new Date(Date.parse(weblink.startDate+"T"+weblink.startTime));
              currentLink.expirationDate = new Date(Date.parse(weblink.endDate+"T"+weblink.endTime));
              updatedWeblinks.push(currentLink);

            } else {
              updatedWeblinks.push(currentLink)
            }
          });

          const request = JSON.stringify(updatedWeblinks);
          await this.upsertPreferenceGrouping(audience, request, false, existingGroupingId);     
        }
      });

      this.openSnackBar(`${weblink.iconText} weblink has been updated.`);

    } catch (error) {
      console.log(`Error updating ${weblink.iconText}:`, error)
    }
    
  }


  /**
   * This method removes a given weblink from the database for all given audiences.
   * 
   * @param weblink - weblink to be removed
   * @param audienceToRemove - audiences from which the weblink should be removed from
   */
  public async removeWeblink(weblink: WeblinkItem, audiencesToRemove: string[]): Promise<void> {
    try {
      const response: ListAllPreferenceGroupingsResp = await this.getWeblinks()
      const preferenceGroupings = response?.preferenceGroupings
  
      for (const audience of audiencesToRemove ?? []) {

        for(const grouping of preferenceGroupings) {
          
          if(grouping.groupingName === audience){
  
            let weblinksString = grouping.groupingPreference
            let weblinkData: WeblinkStorage[] = JSON.parse(weblinksString)
            
            const updatedWeblinks = weblinkData.filter((currentWeblink) => {
              return currentWeblink.url !== weblink.url && currentWeblink.iconText !== weblink.iconText
            })
  
            if(updatedWeblinks.length > 0) {
              const request = JSON.stringify(updatedWeblinks)
              await this.upsertPreferenceGrouping(audience as string, request, false, grouping.groupingId);
              this.openSnackBar(`${weblink.iconText} weblink has been deleted.`);

            } else {
              await new Promise((resolve, reject) => {
              this.userPreferenceService.deletePreferenceGrouping({ groupingInstId: grouping.groupingId })
                ?.subscribe({
                  next: (response: any) => {
                    resolve(response);
                  },
                  error: (err) => {
                    reject(err);
                  },
                });
              })
        
              this.openSnackBar(`${weblink.iconText} weblink has been deleted.`);
            }
          } 
        }
      }
    } catch (error) {
      console.log(`Error removing ${weblink.iconText}:`, error)
    }
  }
  
}
