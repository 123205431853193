import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { XpoAuthenticationGuard } from '@xpo/ngx-auth';
import { WeblinksStatusComponent } from './weblinks-status/weblinks-status.component';
import { WeblinksAddComponent } from './weblinks-add/weblinks-add.component';

const routes: Routes = [
  { path: 'weblinks-status', 
    component: WeblinksStatusComponent, 
    canActivate: [XpoAuthenticationGuard],
  },
  {
    path: 'weblinks-add',
    component: WeblinksAddComponent,
    canActivate: [XpoAuthenticationGuard],
  },
  { path: '', 
    component: WeblinksAddComponent, 
    canActivate: [XpoAuthenticationGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
